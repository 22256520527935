import React from "react";
import classes from "./Footer.module.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={classes.footer}>
      <hr className={classes.hr} />
      <div className={`container ${classes["footer-container"]}`}>
        <div className={classes["footer-left"]}>
          <h3 className={classes["footer-title"]}>Bilgi</h3>
          <ul className={classes.pages}>
            <li className={classes["pages-item"]}>
              <Link to='/hakkimda' className={classes["page-link"]}>Hakkımda</Link>
            </li>
            <li className={classes["pages-item"]}>
              <Link to='/yazilar' className={classes["page-link"]}>Yazılar</Link>
            </li>
            <li className={classes["pages-item"]}>
              <Link to='/iletisim' className={classes["page-link"]}>İletişim</Link>
            </li>
          </ul>
        </div>

        <div className={classes["footer-right"]}>
          &copy; 2023 <Link to="/">Burak Zafer Yıldırım</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
