import React from "react";
import classes from "./Quote.module.css";
import quoteimg from '../../img/quoteimg.jpg'

const Quote = () => {
  return (
    <div className={classes.quote}>
      <div className={classes["quote-left"]}>
        <img src={quoteimg} alt="quote-img" className={classes['quote-img']} />
      </div>
      <div className={classes["quote-right"]}>
        <p className={classes.content}>
          "Her ne olursa olsun mazim bugünkü vaziyetimden bana bütün bir mesele
          gibi geliyor. Ne ondan kurtulabiliyorum, ne de tamamiyle onun emrinde
          olabiliyorum."
        </p>
        <p className={classes["writer"]}>
            Ahmet Hamdi Tanpınar
        </p>
      </div>
    </div>
  );
};

export default Quote;
