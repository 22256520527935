import React, { useEffect } from "react";
import classes from "./Contact.module.css";
import { Link } from "react-router-dom";
import Form from "../components/UI/Form";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className={classes["contact-header"]}>
        <h2 className={classes.title}>İletişim</h2>
        <div className={classes["contact-wrapper"]}>
          <Link to="https://wa.me/5385458082" className={classes["contact-item"]}>
            <img
              src="https://img.icons8.com/color/48/whatsapp--v1.png"
              alt="whatsapplogo"
            />
            <span className={classes.tel}>
              0538 545 80 82
            </span>
          </Link>
          <Link to="mailto:pskburakzaferyildirim@gmail.com" className={classes["contact-item"]}>
            <img
              src="https://img.icons8.com/color/48/gmail-new.png"
              alt="gmaillogo"
            />
            <span
              className={classes.email}
              to="mailto:pskburakzaferyildirim@gmail.com"
            >
              pskburakzaferyildirim@gmail.com
            </span>
          </Link>
        </div>
        <p>
          Bilgi ve randevu almak için yukarıdaki iletişim bilgilerinden veya
          formu doldurarak benimle iletişime geçebilirsiniz.
        </p>
      </div>

      <Form />
    </div>
  );
};

export default Contact;
