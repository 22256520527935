import React, { useRef, useState } from "react";
import classes from "./Form.module.css";
import useInput from "../../hooks/use-input";
import emailjs from "@emailjs/browser";

const isNotEmpty = (value) => value.trim() !== "";

const isPhoneValidFunc = (value) => value.length === 11 && value[0] === "0";

const validateEmail = (value) => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Form = () => {
  const [formState, setFormState] = useState("");
  const form = useRef(<form></form>);

  const {
    value: nameValue,
    isValid: isNameValid,
    hasError: hasNameError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput(isNotEmpty);

  const {
    value: surnameValue,
    isValid: isSurnameValid,
    hasError: hasSurnameError,
    valueChangeHandler: surnameChangeHandler,
    inputBlurHandler: surnameBlurHandler,
    reset: resetSurname,
  } = useInput(isNotEmpty);

  const {
    value: phoneValue,
    isValid: isPhoneValid,
    hasError: hasPhoneError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
    reset: resetPhone,
  } = useInput(isPhoneValidFunc);

  const {
    value: emailValue,
    isValid: isEmailValid,
    hasError: hasEmailError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emalBlurHandler,
    reset: resetEmail,
  } = useInput(validateEmail);

  const {
    value: otherValue,
    isValid: isOtherValid,
    hasError: hasOtherError,
    valueChangeHandler: otherChangehandler,
    inputBlurHandler: otherBlurHandler,
    reset: resetOther,
  } = useInput(isNotEmpty);

  const submitHandler = (e) => {
    setFormState("submitting");
    e.preventDefault();

    console.log(form.current)

    emailjs
      .sendForm(
        "service_k6w1uns",
        "template_6lui0x6",
        form.current,
        "m09mbTq24b9JrCIt4"
      )
      .then(
        (result) => {
          window.alert('Mesajınız başarıyla gönderildi. En kısa sürede sizinle iletişime geçilecektir.')
          setFormState("submitted");
          window.scrollTo(0, 0);
        },
        (error) => {
        }
      );

    resetName();
    resetSurname();
    resetPhone();
    resetEmail();
    resetOther();
    setFormState("");
  };

  const readyToSubmit =
    isNameValid &&
    isSurnameValid &&
    isPhoneValid &&
    isEmailValid &&
    isOtherValid;

  return (
    <form className={classes.form} ref={form} onSubmit={submitHandler}>
      <div className={classes["control-group"]}>
        <div
          className={`${classes["input-group"]} ${hasNameError && "invalid"}`}
        >
          <label htmlFor="name">Ad</label>
          <input
            autoComplete="off"
            value={nameValue}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            type="text"
            id="name"
            name="name"
            placeholder="Adınızı giriniz..."
          />
          {hasNameError && <p>* Lütfen geçerli bir ad giriniz.</p>}
        </div>
        <div
          className={`${classes["input-group"]} ${
            hasSurnameError && "invalid"
          }`}
        >
          <label htmlFor="surname">Soyad</label>
          <input
            autoComplete="off"
            value={surnameValue}
            onChange={surnameChangeHandler}
            onBlur={surnameBlurHandler}
            type="text"
            id="surname"
            name="surname"
            placeholder="Soyadınızı giriniz..."
          />
          {hasSurnameError && <p>* Lütfen geçerli bir soyad giriniz.</p>}
        </div>
      </div>

      <div className={classes["control-group"]}>
        <div
          className={`${classes["input-group"]} ${hasPhoneError && "invalid"}`}
        >
          <label htmlFor="number">Telefon Numarası</label>
          <input
            autoComplete="off"
            value={phoneValue}
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
            type="number"
            id="number"
            name="number"
            placeholder="Telefon numaranızı giriniz..."
          />
          {hasPhoneError && (
            <p>* Lütfen geçerli bir numara giriniz. (örn: 05335112020).</p>
          )}
        </div>
      </div>

      <div className={classes["control-group"]}>
        <div
          className={`${classes["input-group"]} ${hasEmailError && "invalid"}`}
        >
          <label htmlFor="email">E-mail</label>
          <input
            autoComplete="off"
            value={emailValue}
            onChange={emailChangeHandler}
            onBlur={emalBlurHandler}
            type="email"
            id="email"
            name="email"
            placeholder="E-postanızı giriniz..."
          />
          {hasEmailError && (
            <p>* Lütfen geçerli bir e-posta giriniz. (örn: ornek@ornek.com).</p>
          )}
        </div>
      </div>

      <div className={classes["control-group"]}>
        <div
          className={`${classes["input-group"]} ${hasOtherError && "invalid"}`}
        >
          <label htmlFor="other">Mesaj</label>
          <textarea
            autoComplete="off"
            value={otherValue}
            onChange={otherChangehandler}
            onBlur={otherBlurHandler}
            rows={6}
            id="other"
            name="other"
            placeholder=""
          />
          {hasOtherError && <p>* Lütfen bir mesaj giriniz.</p>}
        </div>
      </div>

      <div className={classes.actions}>
        <button
          type="submit"
          disabled={!readyToSubmit}
          className={`${classes["submit-button"]} ${
            !readyToSubmit
              ? "disabled"
              : formState === "submitting"
              ? "disabled"
              : ""
          }`}
        >
          {formState === "submitting" ? "Gönderiliyor" : "Gönder"}
        </button>
      </div>
    </form>
  );
};

export default Form;
