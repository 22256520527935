import React from "react";
import classes from "./Person.module.css";
import me from "../../img/me.jpg";
import symbol from "../../img/sembol.png";

const Person = () => {
  return (
    <div className="container">
      <section className={classes.person}>
        <div className={classes["person-left"]}>
          <img src={me} alt="Burak Zafer Yıldırım" className={classes['person-img']} />
        </div>

        <div className={classes["person-right"]}>
          <img src={symbol} alt="sembol" className={classes.symbol} />
          <h2 className={classes.title}>Burak Zafer Yıldırım</h2>
          <p className={classes.desc}>Psikolog</p>
        </div>
      </section>
    </div>
  );
};

export default Person;
