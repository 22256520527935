import React, { useEffect } from "react";
import classes from "./Articles.module.css";
import { useRouteLoaderData } from "react-router-dom";
import ArticleItem from "../components/ArticleItem";

const Articles = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const articles = useRouteLoaderData('articles');

  return (
    <div className="container">
      <div className={classes["articles-header"]}>
        <h2 className={classes.title}>Yazılarım</h2>
      </div>

      <div className={classes["articles-main"]}>
        <ul className={classes["articles-list"]}>
          {articles.reverse().map((item, index) => {
            return <ArticleItem key={index} article={item} />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Articles;

export const loader = async () => {
  const response = await fetch(
    "https://burak-zafer-yildirim-articles-default-rtdb.firebaseio.com/articles.json"
  );
  const data = await response.json();

  return data;
};
