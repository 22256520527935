import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import RootLayout from "./pages/Root";
import Home, { loader as homeLoader } from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Articles, { loader as articlesLoader } from "./pages/Articles";
import ArticleDetail from "./pages/ArticleDetail";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          index: true,
          element: <Home />,
          loader: homeLoader,
        },
        {
          path: "iletisim",
          element: <Contact />,
        },
        {
          path: "hakkimda",
          element: <About />,
        },
        {
          path: "yazilar",
          loader: articlesLoader,
          id: 'articles',
          children: [
            {
              index: true,
              element: <Articles />,
            },
            {
              path: ":articleTitle",
              element: <ArticleDetail />,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
