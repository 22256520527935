import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";

const width = window.innerWidth;

const Header = () => {
  const [showHeader, setShowHeader] = useState(window.innerWidth >= 1000);

  const handleHeader = () => {
    setShowHeader((prevState) => !prevState);
  };

  const handleClick = () => {
    setShowHeader(false);
  };

  return (
    <div className="container">
      {width < 1000 && (
        <div className={classes["control-nav"]}>
          <img
            src="https://img.icons8.com/ios/50/f2e8c6/menu--v1.png"
            alt="menu"
            className={classes.menu}
            onClick={handleHeader}
          />
        </div>
      )}
      {(showHeader || width >= 1000) && (
        <nav className={classes.nav}>
          <ul className={classes["nav-list"]}>
            <li className={classes["nav-list-item"]}>
              <NavLink
                onClick={handleClick}
                to="/"
                className={classes["nav-link"]}
              >
                Ana Sayfa
              </NavLink>
            </li>
            <li className={classes["nav-list-item"]}>
              <NavLink to={'/hakkimda'} onClick={handleClick} className={classes["nav-link"]}>
                Hakkımda
              </NavLink>
            </li>
            <li className={classes["nav-list-item"]}>
              <NavLink to='yazilar' onClick={handleClick} className={classes["nav-link"]}>
                Yazılar
              </NavLink>
            </li>
            <li className={classes["nav-list-item"]}>
              <NavLink
                onClick={handleClick}
                to="/iletisim"
                className={classes["nav-link"]}
              >
                İletişim
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Header;
