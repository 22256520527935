import React, { useEffect } from "react";
import classes from "./About.module.css";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className={classes["about-header"]}>
        <h2 className={classes.title}>Hakkımda</h2>
        <p className={classes.subtitle}>Burak Zafer Yıldırım</p>
      </div>

      <div className={classes["about-main"]}>
        <p className={classes.paragraph}>
          Merhaba. Ben Burak Zafer Yıldırım. 2023 senesinde İstanbul
          Üniversitesi Psikoloji bölümünden yüksek onur derecesi ile mezun
          oldum. Okul yıllarımda İstanbul Üniversitesi Psikoloji Kulübü çatısı
          altında çeşitli akademik ve sosyal sorumluluk etkinliklerinin
          organizasyonunda aktif görev aldım ve çeşitli etkinliklere katıldım.
        </p>

        <p className={classes.paragraph}>
          Öğrencilik yıllarımda Gaziosmanpaşa Kaymakamlığı tarafından yürütülen
          Beyaz Ev Projesi'nde aktif olarak çalıştım. Bu süreçte Klinik Psikolog
          Hülya Ergün Taşdemir ve Dr. Görkem Gökçelioğlu'nun yanında klinik staj
          deneyimim oldu. Yine Dr. Görkem Gökçelioğlu'ndan "Kişilik Kuramları,
          Kişilik Bozuklukları ve Dinamik Psikoterapi" eğitimimi aldım.
        </p>

        <p className={classes.paragraph}>
          Şu anda süpervizyon eşliğinde yetişkin ve ergenlere yönelik
          Psikanalitik Psikoterapi yapmaktayım. Aynı zamanda aktif olarak Dr.
          Öğr. Üyesi Bengi Pirim Düşgör'den Rorschach ve Projektif Testler
          eğitimimi almaktayım.
        </p>
      </div>
    </div>
  );
};

export default About;
