import React, { useEffect } from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import classes from "./ArticleDetail.module.css";

function Cevir(text) {
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
}

const getCorrectArticle = (articles, name) => {
  for (let article of articles) {
    const title = Cevir(article.title);
    if (title === name) {
      return article;
    }
  }
};

const ArticleDetail = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const articles = useRouteLoaderData("articles");

  const { articleTitle } = useParams();

  const article = getCorrectArticle(articles, articleTitle);

  return (
    <div className="container">
      <div className={classes["detail-header"]}>
        <h2 className={classes.title}>{article.title}</h2>
        <div className={classes.writer}>
          <img
            className={classes.icon}
            src="https://img.icons8.com/material-rounded/24/c09e2c/user.png"
            alt=""
          />
          <span className={classes.name}>Burak Zafer Yıldırım</span>
        </div>
      </div>

      <div className={classes["detail-main"]}>
        <div className={classes["img-wrapper"]}>
          <img src={article.img} alt="" className={classes["article-img"]} />
        </div>
        <div className={classes.content}>
          {article.content.split("   ").map((item, index) => {
            return (
              <p key={index} className={classes.contentP}>
                {item}
              </p>
            );
          })}
        </div>
      </div>

      {article.bibliogrhapy && (
        <div className={classes["article-footer"]}>
          <h3>Kaynakça</h3>
          <ul className={classes.bibliography}>
            {article.bibliogrhapy.map((item, index) => {
              return (
                <li className={classes.bibliography_item} key={index}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;
