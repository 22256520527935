import React, { useEffect } from "react";
import Quote from "../components/Home/Quote";
import Posts from "../components/Home/Posts";
import { useLoaderData } from "react-router-dom";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  const article = useLoaderData()
  return (
    <div className="container">
      <Quote />
      <Posts article={article} />
    </div>
  );
};

export default Home;

export const loader = async () => {
  const response = await fetch(
    "https://burak-zafer-yildirim-articles-default-rtdb.firebaseio.com/articles.json"
  );
  const data = await response.json();

  return data.reverse()[0]
};

