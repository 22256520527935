import React from "react";
import classes from "./ArticleItem.module.css";
import { Link } from "react-router-dom";

function Cevir(text) {
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
}

const ArticleItem = ({ article }) => {
  const articleLink = Cevir(article.title);

  return (
    <li className={classes.item}>
      <h3 className={classes.title}>
        <Link to={`/yazilar/${articleLink}`} className={classes["title-link"]}>
          {article.title}
        </Link>
      </h3>
      <p className={classes.content}>{article.content}</p>
      <Link to={`/yazilar/${articleLink}`} className={classes["post-continue"]}>
        Devamını Okuyun
      </Link>
      <div className={classes.writer}>
        <img
          className={classes.icon}
          src="https://img.icons8.com/material-rounded/24/c09e2c/user.png"
          alt=""
        />
        <span className={classes.name}>Burak Zafer Yıldırım</span>
      </div>
    </li>
  );
};

export default ArticleItem;
