import React from "react";
import classes from "./Posts.module.css";
import { Link } from "react-router-dom";

function Cevir(text) {
  var trMap = {
    çÇ: "c",
    ğĞ: "g",
    şŞ: "s",
    üÜ: "u",
    ıİ: "i",
    öÖ: "o",
  };
  for (var key in trMap) {
    text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
  }
  return text
    .replace(/[^-a-zA-Z0-9\s]+/gi, "")
    .replace(/\s/gi, "-")
    .replace(/[-]+/gi, "-")
    .toLowerCase();
}

const Posts = ({ article }) => {
  const articleLink = Cevir(article.title);

  return (
    <div className={classes.post}>
      <h3 className={classes.title}>Son Paylaşım</h3>

      <div className={classes["post-wrapper"]}>
        <img src={article.img} alt="" className={classes["post-img"]} />

        <div className={classes["post-content"]}>
          <h4 className={classes["post-title"]}>{article.title}</h4>
          <p className={classes["post-p"]}>{article.content}</p>

          <Link
            to={`/yazilar/${articleLink}`}
            className={classes["post-continue"]}
          >
            Devamını Okuyun
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Posts;
